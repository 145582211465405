
import Group from "../components/Group"
import styles from "./SecondScreen.module.css"
import { useRef,useEffect } from "react"
import { Player } from "@lordicon/react"
import helmet from "../icons/wired-lineal-1446-spartan-helmet (2).json"
import Button2 from "../components/Button2"
import Button from "../components/Button"
import ScrollReveal from "scrollreveal"


// אילת- 
// רויאל ביץ׳ 
// שני וחמישי
// 19:00-20:30

// באר שבע-
// פארק מרמלדה 
// ראשון ורביעי
// 19:00-20:30

// עומר-
// ספורטק
// ראשון
// 20:30-22:00

// אשכול-
// עין הבשור
// שני ורביעי
// 18:30-20:00

// ראשון לציון- 
// רחוב תורה ועבודה
// ראשון ורביעי 
// 19:00-20:30

// ירושלים- 
// גן סאקר
// שני וחמישי
// 19:00-20:30

// אריאל-
// פארק הנחל
// ראשון וחמישי 
// 19:00-20:30

// נתניה-
// חוף פולג
// ראשון וחמישי 
// 19:00-20:30

// חיפה-
// חוף הסטודנטים
// ראשון ורביעי 
// 18:30-20:00


const cityList = [
    {
        city: "אילת",
        location: "רויאל ביץ׳",
        day1: "שני",
        hour1: "19:00",
        hour1_end: "20:30",
        day2: "חמישי",
        hour2: "19:00",
        hour2_end: "20:30",
    },
    {
        city: "באר שבע",
        location: "פארק מרמלדה",
        day1: "ראשון",
        hour1: "19:00",
        hour1_end: "20:30",
        day2: "רביעי",
        hour2: "19:00",
        hour2_end: "20:30",
    },
    {
        city: "עומר",
        location: "ספורטק",
        day1: "ראשון",
        hour1: "20:30",
        hour1_end: "22:00",
    },
    {
        city: "אשכול",
        location: "עין הבשור",
        day1: "שני",
        hour1: "18:30",
        hour1_end: "20:00",
        day2: "רביעי",
        hour2: "18:30",
        hour2_end: "20:00",
    },
    {
        city: "ראשון לציון",
        location: "רחוב תורה ועבודה",
        day1: "ראשון",
        hour1: "19:00",
        hour1_end: "20:30",
        day2: "רביעי",
        hour2: "19:00",
        hour2_end: "20:30",
    },
    {
        city: "ירושלים",
        location: "גן סאקר",
        day1: "שני",
        hour1: "19:00",
        hour1_end: "20:30",
        day2: "חמישי",
        hour2: "19:00",
        hour2_end: "20:30",
    },
    {
        city: "אריאל",
        location: "פארק הנחל",
        day1: "ראשון",
        hour1: "19:00",
        hour1_end: "20:30",
        day2: "חמישי",
        hour2: "19:00",
        hour2_end: "20:30",
    },
    {
        city: "נתניה",
        location: "חוף פולג",
        day1: "ראשון",
        hour1: "19:00",
        hour1_end: "20:30",
        day2: "חמישי",
        hour2: "19:00",
        hour2_end: "20:30",
    },
    {
        city: "חיפה",
        location: "חוף הסטודנטים",
        day1: "ראשון",
        hour1: "18:30",
        hour1_end: "20:00",
        day2: "רביעי",
        hour2: "18:30",
        hour2_end: "20:00",
    },
]

const SecondScreen=()=>{

    const handleComplete=()=>{
        setTimeout(()=>{
            {
                playerRef1?.current?.playFromBeginning()}
        },2500)
    }
    const playerRef1=useRef(null)
    useEffect(()=>{
        playerRef1?.current?.playFromBeginning()
    
        ScrollReveal().reveal(`.${styles.groups}`, {
            duration: 500,
            distance: "60px",
            origin: "top",
            easing: "ease-out",
            reset: false,
            viewFactor: 0.2,
            interval: 300,
            delay: 200,
            scale: 1,
          });
    
    },[])

 


return <>

<div className={styles.container} id="הצוותים שלנו">
<svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,224L80,213.3C160,203,320,181,480,192C640,203,800,245,960,261.3C1120,277,1280,267,1360,261.3L1440,256L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
    <div className={styles.groups}>הצוותים שלנו</div>
    <div className={styles.icon}><Player ref={playerRef1} size="100%" onComplete={handleComplete} icon={helmet}/></div>
    {/* <div className={styles.row}> */}

{ cityList.map((group,index)=> {

    if (index % 2 === 0 && index < cityList.length - 1) {
        let group1 = cityList[index]
        let group2 = cityList[index + 1]

        return <div className={styles.row}>
            <Group key={index} city={group1.city} location={group1.location} day1={group1.day1} hour1={group1.hour1} hour1_end={group1.hour1_end} day2={group1.day2} hour2={group1.hour2} hour2_end={group1.hour2_end} />
            <Group key={index + 1} city={group2.city} location={group2.location} day1={group2.day1} hour1={group2.hour1} hour1_end={group2.hour1_end} day2={group2.day2} hour2={group2.hour2} hour2_end={group2.hour2_end} />
        </div>
    } else if (index % 2 === 1) {
        return;
    } else {
        return <div className={styles.row}>
            <Group key={index} city={group.city} location={group.location} day1={group.day1} hour1={group.hour1} hour1_end={group.hour1_end} day2={group.day2} hour2={group.hour2} hour2_end={group.hour2_end} />
        </div>
    }
})}

{/* <Group city="אטזבקיסטן" day1="ראשון" hour1="18:00" day2="רביעי" hour2="19:30"/>
<Group city="לונדון" day1="ראשון" hour1="17:00" day2="חמישי" hour2="18:30"/>
</div>
<div className={styles.row}>
<Group city="ירושלים" day1="שני" hour1="19:00" day2="רביעי" hour2="20:30"/>
<Group city="באר שבע" day1="ראשון" hour1="19:00" day2="רביעי" hour2="20:30"/> */}
{/* </div> */}
<div className={styles.center}><Button msg="אני מעוניין לקבל פרטים נוספים על הצוותי כושר קרבי שלכם "/></div>
</div>

</>



}
export default SecondScreen