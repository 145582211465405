import styles from "./TargetLoader.module.css"
const TargetLoader=()=>{
return <>
<div className={styles.container}>
<div className={styles.loader}>
    <span></span>
</div>
</div>
</>

}
export default TargetLoader